const races =[




    {
        title: 'FIS Telemark Worldcup Finals 2025',
        text: ' ',
        text2:'',
        text3:'Thyon accueillera les meilleurs athlètes pour clore la saison 2024-2025 :',
        text4:'',
        text5:'',
        text6:'',
        text7: ' ',
        link:'',
        linkText:'Plateforme bénévole',
        img: process.env.PUBLIC_URL + 'images/fis2024/affiche.png',
        category:'All',

    },

    {
        title: 'Inscrivez-vous comme bénévole',
        text: ' Nous avons mis en place une nouvelle plateforme pour la gestion des bénévoles. Nous aimerions faciliter la communication avec vous.',
        text2:'',
        text3:'Inscrivez-vous dès maintenant et découvrez la nouvelle plateforme :',
        text4:'',
        text5:'',
        text6:'',
        text7: '',
        link:'https://app.qoezion.com/apply/2ea13e6b-d6ef-4ec9-a78d-c98199cae34a?tags=U2l0ZSBpbnRlcm5ldA==&sig=SENav2IgaVfGPDwpAZJJtCTOVxlVFZAJnXDLFDBTQG0',
        linkText:'Plateforme bénévole',
        img: process.env.PUBLIC_URL + 'images/STC2023/volunteer.jpg',
        category:'All',

    },
    {
        title: 'Partenaires',
        text: ' Nous remercions tous nos partenaires qui nous soutiennent et qui permettent le bon déroulement de cette manifestation. ',
        text2:' ',
        text3:'Devenez aussi partenaire. Vous trouverez plus de renseignements dans notre dossier de présentation: ',
        text4:'',
        text5:'',
        text6:'',
        text7: '',
        link:'docs/DossierSponsoringWC2025.pdf',
        linkText:'Dossier Présentation',
        img: process.env.PUBLIC_URL + 'images/2021/2021_4.jpg',
        category:'All',

    }


];

export default races;