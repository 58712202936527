import React from 'react';
import {Box, Typography} from "@mui/material";
import advantages from "../store/advantages";
import ImageCard from "../components/ImageCard";

function Advantages() {




    return (
        <div>
            <Typography sx={{marginLeft:3}} fontSize="2rem" color="text.primary">

Avantages membres

            </Typography>
            <Box
                bgcolor="#ffff"
                flex={4}
                p={2}
                sx={{display:{xs:"block",lg:"flex"},margin:1,}}
            >


                <Box
                    sx={{justifyItems:'center',
                        alignItems:'',
                        flexDirection: 'row',
                        display:'inline-flex',
                        flexWrap: 'wrap',



                    }}>


                    {advantages.map(history =>(

                        <ImageCard img={history.img} title={history.title} text={history.text} text2={history.text2} text3={history.text3} link={history.link}
                            linkText={history.linkText}/>


                    ))}

                </Box>

            </Box>

        </div>
    );
}

export default Advantages;
