const mouchtec =[




    {
        title: 'Mouch\'Tec',
        text: 'Le Mouch\'Tec est le groupe d\'entraînement du Mouch\'PaBa. Il est ouvert à toutes les personnes qui souhaitent découvrir le télémark ou améliorer leur technique. Actuellement, il y a plus de 20 jeunes entre 10 et 20 ans."',
        text2:'Des activités régulières ont lieu toute la saison.',
        text4:'Plus d\'informations',
        img: process.env.PUBLIC_URL + 'images/mouchtec/mouch_2.jpg',
        category:'All',

    },



];


export default mouchtec;
