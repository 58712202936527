import React from 'react';
import {Stack} from "@mui/material";
import Feed from "../layout/Feed";
import RightBar from "../layout/RightBar";

function Home() {
    return (

        <Stack direction="row">
            <Feed/>
            <RightBar/>
        </Stack>

    );
}

export default Home;
