import {createTheme} from "@mui/material";

export const theme =createTheme({


        palette: {
                primary: {
                        light: '#2c2c2c',
                        main: '#000000',
                        dark: '#000000',
                        contrastText: '#89d9e4',
                },
                secondary: {
                        light: '#bcffff',
                        main: '#89d9e4',
                        dark: '#56a7b2',
                        contrastText: '#000',

                },
        },

});
