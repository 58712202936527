import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import I18NextHttpBackend from "i18next-http-backend";

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(I18NextHttpBackend)

    .init({
        lng: 'fr',
        debug: true,
        fallbackLng: 'fr',

    })



export default i18n;
