

const advantages
=[

{
    img: process.env.PUBLIC_URL + 'images/logos/theytaz.jpg',
    title: 'Rabais de 15% sur tout le textile et les accessoires',
    text: 'Annoncer votre appartenance au club lors de votre achat',
    text2: '',
    text3: '',
    link:'https://theytazsports.ch/',
    linkText:'Lien site partenaire',

},
    {
        img: process.env.PUBLIC_URL + 'images/logos/cavelait.png',
        title: 'Soutenez les activités de nos jeunes',
        text: 'Fondue à 15.- pour 400 grammes',
        text2: '',
        text3: '',
        link:'https://www.mouchtec.ch/nous-soutenir',
        linkText:'Lien commande',

    },
    ]

export default advantages;