import React from 'react';
import {Box, Typography} from "@mui/material";
import history from "../store/history";
import ImageClub from "../components/ImageClub";
import Commitee from "./Commitee";

function Club() {




    return (
        <div>
            <Typography sx={{marginLeft:3}} fontSize="2rem" color="text.primary">

                { "Le Club"}

            </Typography>
            <Box
                bgcolor="#ffff"
                flex={4}
                p={2}
                sx={{display:{xs:"block",lg:"flex"},margin:1,}}
            >


                <Box
                    sx={{justifyItems:'center',
                        alignItems:'',
                        flexDirection: 'row',
                        display:'inline-flex',
                        flexWrap: 'wrap',



                    }}>


                    {history.map(history =>(

                        <ImageClub img={history.img} title={history.title} text={history.text} text2={history.text2} text3={history.text3}/>


                    ))}
                    <Commitee/>
                </Box>

            </Box>

        </div>
    );
}

export default Club;
