

const imagesHome = [
    {
        img: process.env.PUBLIC_URL + 'images/alexi.jpg',
        title: 'Race',
        author: '@360dsm',
        featured: true,
    },
    {
        img: process.env.PUBLIC_URL + 'images/lea.jpg',
        title: 'Race',
        author: '@360dsm',
        featured: true,
    },
    {
        img: process.env.PUBLIC_URL + 'images/mariejulie.jpg',
        title: 'Race',
        author: '@360dsm',
        featured: true,
    },
    {
        img: process.env.PUBLIC_URL + 'images/2024mouchtec/mouch2.jpg',
        title: 'Race',
        author: '@360dsm',
        featured: true,
    },
    {
        img: process.env.PUBLIC_URL + 'images/STC2023/MS5.jpg',
        title: 'SwissChamps',
        author: '@360dsm',
        featured: true,
    },
    {
        img: process.env.PUBLIC_URL + 'images/telemarkRace.jpg',
        title: 'Telemark',
        author: '@360dsm',
        featured:true,
    },


    {
        img: process.env.PUBLIC_URL + 'images/STC2023/telekids5.jpg',
        title: 'telekidz',
        author: '@fistelemark',
        featured: true,
    },
    {
        img: process.env.PUBLIC_URL + 'images/STC2023/MS4.jpg',
        title: 'STC2023',
        author: '@360dsm',
        featured: true,
    },
    {
        img: process.env.PUBLIC_URL + 'images/2017/2017_7.jpg',
        title: 'CM2017',
        author: '@philippnendaz',
        featured: true,
    },
    {
        img: process.env.PUBLIC_URL + 'images/STC2023/telekids4.jpg',
        title: 'telekidz',
        author: '@fistelemark',
        featured: true,
    },


    {
        img: process.env.PUBLIC_URL + 'images/2024mouchtec/mouch8.jpg',
        title: 'mouchtec',
        author: 'mouchtec',
        featured: true,
    },
    {
        img: process.env.PUBLIC_URL + 'images/2024mouchtec/mouch9.jpg',
        title: 'mouchtec',
        author: '@mouchtec',
        featured: true,
    },

    {
        img: process.env.PUBLIC_URL + 'images/STC2023/telekids1.jpg',
        title: 'telekidz',
        author: '@fistelemark',
        featured: true,
    },
    {
        img: process.env.PUBLIC_URL + 'images/STC2023/telekids2.jpg',
        title: 'telekidz',
        author: '@fistelemark',
        featured: true,
    },
    {
        img: process.env.PUBLIC_URL + 'images/STC2023/telekids3.jpg',
        title: 'telekidz',
        author: '@fistelemark',
        featured: true,
    },







];
export default imagesHome;
