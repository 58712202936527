import React from 'react';

import {Typography,Box} from "@mui/material";

import activities from "../store/activities";
import ImageCard from "../components/ImageCard";





const sortedDates=activities.sort(function(a,b){
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    // @ts-ignore

        return new Date(a.date) - new Date(b.date);

});

const filteredDates = sortedDates.filter(
    function (a)
    {
        // @ts-ignore
        return (new Date(a.date)-new Date()>0);
    });

const first2Element=filteredDates.slice(0,2);




function ActivitiesFirst() {
   

    return (
        <div>

            <Box
                bgcolor="#ffff"
                flex={4}
                p={2}
                sx={{display:{xs:"block",sm:"block"}}}
            >
                <Typography sx={{margin:1}} fontSize="1.2rem" color="text.primary">

                    { "Les prochaines activités du club"}

                </Typography>

                <Box
                    sx={{justifyItems:'center',
                        alignItems:'',
                        flexDirection: 'row',
                        display:'inline-flex',
                        flexWrap: 'wrap',


                    }}>

                    {first2Element.map(activity =>(

                            <ImageCard img={activity.img} title={activity.title} text={activity.text} text2={activity.text2} text3={activity.text3} link={activity.link} linkText={activity.linkText}/>


                    ))}
                </Box>
            </Box>


        </div>
    );
}

export default ActivitiesFirst;
