import React from 'react';
import HallofFameCard from "../components/HallofFameCard";
import success from "../store/success";

function HallofFame() {
    return (
        <div style={{alignItems:"center"}}>
            {success.map(successes =>(
            <HallofFameCard title={successes.title} img={successes.img} text={successes.text} text2={successes.text2} text3={successes.text3} text4={successes.text4}/>
            ))}
            </div>
    );
}

export default HallofFame;
