import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import CopyrightIcon from '@mui/icons-material/Copyright';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from "@mui/icons-material/Facebook";
import {useNavigate} from "react-router-dom";

export default function SimpleBottomNavigation() {
    const [value, setValue] = React.useState(0);
    const navigate=useNavigate();

    return (
        <Box sx={{ display:{ md: 'flex' }, alignItems:'center',justifyContent:'center', }}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >

                <BottomNavigationAction onClick={() => {

                    window.open("mailto:info@mouchpaba.ch")
                }} label="Mail" icon={<MailIcon />} />
                <BottomNavigationAction onClick={()=>
                {navigate("/impressum")

                }}

                    label="Mouch'Paba" icon={<CopyrightIcon />} />
                <BottomNavigationAction
                    label="Instagram"
                    value="Instagram"
                    onClick={() => {

                        window.open("https://www.instagram.com/telemarkworldcupthyon/")
                    }}
                    icon={<InstagramIcon/>}
                />
                <BottomNavigationAction
                    label="Facebook"
                    value="Facebook"
                    onClick={() => {

                        window.open("https://fr-fr.facebook.com//telemarkworldcupthyon")
                    }}
                    icon={<FacebookIcon />}
                />

            </BottomNavigation>
        </Box>
    );
}
