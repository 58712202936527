import {Box, Card, CardMedia} from "@mui/material";
import React from 'react';
import  { useTranslation } from 'react-i18next';
import Typography from "@mui/material/Typography";




interface content {
img:string;
title:string;
text:string;//jch
text2:string;//champch
text3:string;//victories
text4:string;//globes

}




function HallofFameCard(success:content) {
    const { t } = useTranslation();

    return (
        <Box  sx={{width:"100%",height:"auto",margin:1,justifyItems:'center',
            alignItems:'',
            flexDirection: 'row',
            display:'flex',
            flexWrap: 'wrap'}}>

        <Box sx={{width:"100%",height:"auto",margin:1,justifyItems:'center',
            alignItems:'',
            flexDirection: 'row',
            display:'flex',
            flexWrap: 'wrap'}} >

            <Box
                sx={{
                    width: {xs:"100%",sm:"50%"},
                    height: "auto",
                    margin:1}}
            bgcolor={"lightgray"}>
                <Typography  sx={{margin:1, textAlign: 'center'}}  fontSize="1.2rem" color="text.primary">{success.title}</Typography>

                <CardMedia
                    component="img"

                    image={success.img}
                    alt={success.img}
                />

                </Box>
            <Box
                sx={{
                    width: 180,
                    height: 180,
                    margin:1,
                    }}
                bgcolor="lightblue"
                >
                <Typography  sx={{margin:1, textAlign: 'center'}}  fontSize="1rem" color="text.primary">Médailles Juniors</Typography>

                <Typography  sx={{margin:1, textAlign: 'center'}}  fontSize="4rem" color="text.primary">{success.text}</Typography>

            </Box>
            <Box
                sx={{
                    width: 180,
                    height: 180,
                    margin:1,
                }}
                bgcolor="lightslategray"
            >
                <Typography  sx={{margin:1, textAlign: 'center'}}  fontSize="1rem" color="text.primary">Médailles Mondiaux</Typography>

                <Typography  sx={{margin:1, textAlign: 'center'}}  fontSize="4rem" color="text.primary">{success.text2}</Typography>

            </Box>
            <Box
                sx={{
                    width: 180,
                    height: 180,
                    margin:1,
                }}
                bgcolor="lightskyblue"
            >
                <Typography  sx={{margin:1, textAlign: 'center'}}  fontSize="1rem" color="text.primary">Victoires CM</Typography>

                <Typography  sx={{margin:1, textAlign: 'center'}}  fontSize="4rem" color="text.primary">{success.text3}</Typography>

            </Box>
            <Box
                sx={{
                    width: 180,
                    height: 180,
                    margin:1,
                }}
                bgcolor="lightsteelblue"
            >
                <Typography  sx={{margin:1, textAlign: 'center'}}  fontSize="1rem" color="text.primary">Globes</Typography>

                <Typography  sx={{margin:1, textAlign: 'center'}}  fontSize="4rem" color="text.primary">{success.text4}</Typography>
            </Box>
            </Box>
        </Box>



    );
}

export default HallofFameCard;
