const success =[

    {
        img:process.env.PUBLIC_URL + 'images/romain/romain2.jpeg',
        title:"Romain Beney",
        text:"2",//jch
        text2:"0",//champch
        text3:"0",//victories
        text4:"0",//globes
    },



    {
        img:process.env.PUBLIC_URL + 'images/mariejulie.jpg',
        title:"Marie-Julie Huber",
        text:"1",//jch
        text2:"0",//champch
        text3:"0",//victories
        text4:"0",//globes
    },
    {
        img:process.env.PUBLIC_URL + 'images/lea.jpg',
        title:"Léa Lathion",
        text:"3",//jch
        text2:"0",//champch
        text3:"0",//victories
        text4:"1",//globes
    },
    {
        img:process.env.PUBLIC_URL + 'images/NM_Decout_1.jpg',
        title:"Nicolas Michel",
        text:"10",//jch
        text2:"6",//champch
        text3:"13",//victories
        text4:"4",//globes
    },
    {
        img:process.env.PUBLIC_URL + 'images/alexi.jpg',
        title:"Alexi Mosset",
        text:"1",//jch
        text2:"1",//champch
        text3:"1",//victories
        text4:"0",//globes
    },
    {
        img:process.env.PUBLIC_URL + 'images/maxime/maxime7.jpeg',
        title:"Maxime Mosset",
        text:"0",//jch
        text2:"0",//champch
        text3:"0",//victories
        text4:"0",//globes
    },
    {
        img:process.env.PUBLIC_URL + 'images/francoise.jpg',
        title:"Françoise Besse-Matter",
        text:"0",//jch
        text2:"1",//champch
        text3:"2",//victories
        text4:"1",//globes
    },


    {
        img:process.env.PUBLIC_URL + 'images/AR_Borga_1.jpg',
        title:"Amélie Wenger-Reymond",
        text:"3",//jch
        text2:"26",//champch
        text3:"164",//victories
        text4:"47",//globes
    },
    {
        img:process.env.PUBLIC_URL + 'images/BD_Borga_1.jpg',
        title:"Bastien Dayer",
        text:"3",//jch
        text2:"10",//champch
        text3:"46",//victories
        text4:"15",//globes
    },



];


export default success;
