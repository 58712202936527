const history =[




    {
        title: 'Le Mouch\'Paba Telemark Club',
        text: 'Le Mouch\'Paba est un club de télémark situé à Thyon dans le domaine des 4 Vallées. Il compte actuellement une centaine de membres.',
        text2:'Le club compte parmi ses membres les meilleurs télémarkeurs du monde. Françoise Besse, Amélie Wenger-Reymond, Bastien Dayer et Nicolas Michel ont gagné plus de 225 victoires en coupe du monde et plus de 20 titres de champions du monde.',
        text3:'Le Mouch’Paba est peut-être le club de sports de neige le plus titré en Suisse. Le club s’engage également activement pour la jeunesse et la promotion du télémark avec le Mouch’Tec. Le but du Mouch’Tec est de faire découvrir le télémark et d’accompagner les jeunes télémarkeurs dans la pratique de ce sport. Différentes sorties et évènements sont organisés pour les membres de tout âge durant l\'année pour faire vivre l’esprit de club.\n',
        img: process.env.PUBLIC_URL + 'images/alexi.jpg',
        category:'All',

    },














];


export default history;
