import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import {Card, IconButton} from "@mui/material";
import {Download} from "@mui/icons-material";

export default function AlignItemsList() {
    return (
        <Card sx={{ maxWidth: 345, margin:1 }}>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>



                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="Président" src="images/romain.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                        primary="Président"
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    Samuel Lebsir
                                </Typography>



                            </React.Fragment>
                        }
                    />
                </ListItem>


                <Divider variant="inset" component="li" />

            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Vice-président" src="images/mariejulie.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Vice Président"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                               Raphaël Gaillard
                            </Typography>



                        </React.Fragment>
                    }
                />
            </ListItem>


            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Secretary" src="images/amelie.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Secrétaire"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Amélie Wenger-Reymond
                            </Typography>



                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Financial" src= "images/nico.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Caissier"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                               François Théytaz
                            </Typography>
                            {}
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="President" src="images/bastien.jpg" />
                </ListItemAvatar>
                <ListItemText
                    primary="Membre"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Johny Beney
                            </Typography>
                            {}
                        </React.Fragment>
                    }
                />
            </ListItem>

            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
            <ListItemText
                primary="Contact:"
                secondary={
                    <React.Fragment>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >

                        </Typography>
<IconButton onClick={() => {

    window.open("mailto:info@mouchpaba.ch")
}}>
                        <EmailIcon   />
</IconButton>
                    </React.Fragment>
                }
            />
                <ListItemText
                    primary="Statuts:"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >

                            </Typography>
                            <IconButton onClick={() => {

                                window.open(process.env.PUBLIC_URL + 'docs/Club_Statuts.pdf')
                            }}>
                                <Download  />
                            </IconButton>
                        </React.Fragment>
                    }
                />
                </ListItem>




        </List>
        </Card>
    );
}
