import {Card, CardMedia, CardContent, Typography, Button} from '@mui/material';
import React, {useEffect, useState} from 'react';

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram"


interface content {
img:string;
title:string;
text:string;
text2:string;
text4:string;

}




function ImageMouchtec(activities:content) {

    const[checked,setChecked]=useState(true);
    useEffect(() => {
        setInterval(() => {
            setChecked(false);
        }, 25000);
    }, []);

    return (
        <Card  sx={{width:{sm:"100vh",lg:"90vh",xl:"75vh"}}} >
<Box
    sx={{display:"flex",
    justifyContent:"center",
    alignItems:"center",

   }}
>
<Box >

    {checked?
        <CardMedia
            component="img"
            height="600"
            image={activities.img}
            alt={activities.title}
        />
    :null}
</Box>
</Box>
                            <CardContent >
                    <Typography paragraph></Typography>
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">
                       { activities.text}
                    </Typography>

                    {activities.text2 ?
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">
                        {activities.text2}

                    </Typography>
                   :null }
                    {activities.text4 ?
                        <Button
                            sx={{ m: 1, width: '25ch' }}
                            color="secondary"
                            type="submit"
                            variant="contained"

                            onClick={() => {

                            window.open("https://www.mouchtec.ch")
                        }}
                        >{activities.text4}</Button>

                        :null }





                                <IconButton color="primary" aria-label="Instagram image" component="label"
                                           >

                                    <InstagramIcon
                                        onClick={() => {

                                            window.open("https://www.instagram.com/mouchtec/")
                                        }}
                                    />
                                </IconButton>

                </CardContent>

        </Card>

    );
}

export default ImageMouchtec;
