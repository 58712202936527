import * as React from 'react';
import {Box, Typography} from "@mui/material";
import races from "../store/races";
import ImageRace from "../components/ImageRace";
import {useEffect, useState} from "react";
import HomeCard from "../components/HomeCard";


export default function SwissChamps() {

    const[checked,setChecked]=useState(true);
    useEffect(() => {
        setInterval(() => {
            setChecked(false);
        }, 12000);
    }, []);


    return (

        <div >

            {checked? (

                <Box >
                    <Box sx={{display:'flex'}}>
                        <video src={process.env.PUBLIC_URL + 'images/fis2024/WC2025.mp4'} height="1000" style={{width:"100%",height:"auto"}}autoPlay muted loop />
                    </Box>
                </Box>):null}




            <Box
                bgcolor="#ffff"
                flex={4}
                p={2}
                sx={{display:{xs:"block",lg:"flex"},margin:3,}}

            >


                <Box
                    sx={{justifyItems:'center',
                        alignItems:'',
                        flexDirection: 'row',
                        display:'inline-flex',
                        flexWrap: 'wrap',



                    }}>


                    {races.map(history =>(

                        <ImageRace img={history.img} title={history.title} text={history.text} text2={history.text2} text3={history.text3} text4={history.text4} text5={history.text5} text6={history.text6} text7={history.text7} link={history.link} linkText={history.linkText}/>


                    ))}


                </Box>


            </Box>

        </div>
    );
}





