import React from 'react';
import Box from "@mui/material/Box";





function RightBar() {
    return (
<>
        <Box
            bgcolor="#ffff"
            flex={1}
            p={2}
            sx={{display:{md:"none",xs:"none",lg:"block"}, alignItems:'center',}}
        >

            <Box>


            </Box>
        </Box>

    </>
    );
}

export default RightBar;
