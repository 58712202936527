import React from 'react';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import Home from "./pages/Home";
import SimpleBottomNavigation from "./navigation/SimpleBottomNavigation";
import ResponsiveAppBar from "./navigation/ResponsiveAppBar";
import {ThemeProvider} from "@mui/material";
import {theme} from "./styling/GlobalTheme";
import Club from "./pages/Club";
import Activities from "./pages/Activities";
import SwissChamps from "./pages/SwissChamps";
import Impressum from "./pages/Impressum";
import HallofFame from "./pages/HallofFame";
import Mouchtec from "./pages/Mouchtec";
import Advantages from "./pages/Advantages";



function App() {
  return (
      <ThemeProvider theme={theme}>
    <BrowserRouter>
        <ResponsiveAppBar/>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/home" element={<Home/>}/>
        <Route path={"/club"} element={<Club/>}/>
        <Route path={"/advantages"} element={<Advantages/>}/>
        <Route path={"/activites"}element={<Activities/>}/>
        <Route path={"/mouchtec"}element={<Mouchtec/>}/>
        <Route path={"/race"} element={<SwissChamps/>}/>
        <Route path={"/course"}element={<SwissChamps/>}/>
        <Route path={"/impressum"} element={<Impressum/>}/>
        <Route path={"*"} element ={<Home/>}/>
        <Route path={"/champions"} element={<HallofFame/>}/>
      </Routes>
        <SimpleBottomNavigation/>
    </BrowserRouter>
      </ThemeProvider>
  );
}

export default App;
