import {Card, CardMedia, CardContent, Typography, Button} from '@mui/material';
import React from 'react';





interface content {
img:string;


}




function ImageCard(activities:content) {


    return (

        <Card sx={{  margin:0 }}>
            <div style={{ position: "relative" }}>
            <CardMedia
                component="img"
                width="100%"
                height="auto"
                image={activities.img}
                alt={activities.img}
            />

            <Typography   style={{fontSize:"3rem",position: "absolute", color: "#56a7b2",top: 150,left: "50%",transform: "translateX(-50%)",}}> {("")}</Typography>

            </div>
        </Card>

    );
}

export default ImageCard;
