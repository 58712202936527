import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import imagesHome from "../store/imagesHome";
import HomeCard from "../components/HomeCard";
import ActivitiesFirst from "../pages/ActivitiesFirst";
import TitleHomeCard from "../components/TitleHomeCard";









function Feed() {

    const [name,setName]=useState(Object)
    const [checked,setChecked]=useState(false);

    useEffect(() => {
        setInterval(() => {
            random_item(imagesHome)
            setName(random_item(imagesHome))
        }, 4000);
    }, []);

    useEffect(() => {
        setInterval(() => {
            setChecked(false)
        }, 20000);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setChecked(true)
        }, 4000);
    }, []);

    function random_item(imagesHome:any)
    {

        return (imagesHome[Math.floor(Math.random()*imagesHome.length)]);

    }







    return (
        <>

            <Box sx={{display:{xs:"flex",sm:"flex"},
                flexWrap:"wrap",alignContent:"start"

            }}>
                {checked?
<div style={{width:"100%",height:"auto"}}>
                    <HomeCard img={name.img}/>

</div>
            : <TitleHomeCard />   }







                <ActivitiesFirst/>

            </Box>
        </>
    );
}

export default Feed;
