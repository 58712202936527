import React from 'react';
import Box from "@mui/material/Box";
import AlignItemsList from "../components/AlignItemsList";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";

function Commitee() {
    const {t}=useTranslation();
    return (

        <div>
            <Box
                bgcolor="#ffff"
                flex={4}
                p={2}
                sx={{display:{xs:"100vh",sm:"100vh"},margin:1}}
            >
                <Typography sx={{margin:1}} fontSize="1.2rem" color="text.primary">

                    { t("committee_title")}

                </Typography>

                <Box
                    sx={{justifyItems:'center',
                        alignItems:'',
                        flexDirection: 'row',
                        display:'inline-flex',
                        flexWrap: 'wrap',


                    }}>
                <AlignItemsList/>

            </Box>

        </Box>
        </div>
    );
}

export default Commitee;
