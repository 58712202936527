import {Card, CardMedia, CardContent, Typography, Button} from '@mui/material';
import React, {useEffect, useState} from 'react';
import  { useTranslation } from 'react-i18next';
import SwipeableTextMobileStepper from "./diaporama/SwipeableTextMobileStepper";
import Box from "@mui/material/Box";


interface content {
img:string;
title:string;
text:string;
text2:string;
text3:string;

}




function ImageClub(activities:content) {
    const { t } = useTranslation();

    const[checked,setChecked]=useState(true);
    useEffect(() => {
        setInterval(() => {
            setChecked(false);
        }, 25000);
    }, []);

    return (
        <Card  sx={{width:{sm:"100vh",lg:"90vh",xl:"75vh"}}} >
<Box
    sx={{display:"flex",
    justifyContent:"center",
    alignItems:"center",

   }}
>
<Box >

    {checked?
           <SwipeableTextMobileStepper />
    :null}
</Box>
</Box>
            <CardContent>
                <Typography fontSize="1.2rem" color="text.primary">

                    {t(`${activities.title}`)}

                </Typography>
            </CardContent>


                <CardContent >
                    <Typography paragraph></Typography>
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">
                       { t(`${activities.text}`)}
                    </Typography>

                    {activities.text2 ?
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">
                        {t(`${activities.text2}`)}

                    </Typography>
                   :null }
                    {activities.text3 ?

                        <Typography paragraph fontSize="1.1rem" color="text.secondary">
                          { t(`${activities.text3}`)}

                        </Typography>

                        :null }




                </CardContent>






        </Card>

    );
}

export default ImageClub;
