import {Card, CardMedia, CardContent, Typography, Button} from '@mui/material';
import React from 'react';




interface content {
img:string;
title:string;
text:string;
text2:string;
text3:string;
link:string;
linkText:string;

}




function ImageCard(activities:content) {


    return (

        <Card   sx={{ bgcolor:"#bcffff",width:{sm:"100vh",lg:"90vh",xl:"75vh"}, margin:1 }}>

            <CardMedia
                component="img"
                height="400"
                image={activities.img}
                alt={activities.img}
            />
            <CardContent>

                {activities.text2 ?
                    <Typography paragraph fontSize="1.1rem"  color="text.primary">
                        {activities.text2}

                    </Typography>
                    :null }

                <Typography fontSize="1.6rem" color="text.secondary">

                    {activities.title}

                </Typography>
            </CardContent>


                <CardContent >
                    <Typography paragraph></Typography>
                    <Typography paragraph>
                       { activities.text}
                    </Typography>


                    {activities.text3 ?
                        <Typography paragraph fontSize="1.0rem" color="text.secondary">
                          { activities.text3}

                        </Typography>
                        :null }
                    {activities.link ?
                        <Button color="secondary" sx={{ m: 1, width: '25ch' }}
                                type="submit"
                                variant="contained" onClick={() => {

                            window.open(activities.link)
                        }}
                        >{activities.linkText}</Button>
                        :null }


                </CardContent>

        </Card>

    );
}

export default ImageCard;
